@import 'node_modules/dragula/dist/dragula.min.css';

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
  max-width: 80vw !important;

  .drag-handle {
    position: absolute;
    left: 3px;
    top: 1px;
    z-index: 9;
    background: #fff;
    height: 56px;
    width: 60px;
    opacity: 0;
    border-radius: 60px;
    --border-radius: 60px;
  }

}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}