html {

    app-login {

        .pipeline-logo {
            z-index: 10 !important;
        }

        .login-style-helper {
            content: '';
            position: fixed;
            top: 0px;
            left: 0px;
            background-size: contain;
            z-index: 10 !important;
            background-repeat: no-repeat;
            background-position: top left;

            @media screen and (max-width: 1279px) {
                position: absolute;
            }
        }

        .curve {
            display: none !important;
        }

        .form {
            position: relative;
            z-index: 9;

            &.useCase {
                &::before {
                    content: '';
                    background: url(./../../assets/img/login/pipeline-devices-dark-profile-home-de.png) no-repeat center center var(--pipeline-login-web-left-area-background-color, #e2d8ff);
                    display: block !important;
                    background-size: cover !important;

                    @media screen and (max-width: 1279px) {
                        background-size: cover !important;
                        top: 0px;
                        left: 0px;
                        width: 100vw;
                        height: 100vh;
                        position: fixed;
                    }
                }
            }

            @media screen and (max-width: 1279px) {
                .legal-info-text {
                    margin-top: 30px !important;
                }
            }

            @media screen and (max-width: 768px) {
                ion-card {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }

            form {
                max-width: 450px;
                margin: auto;
                display: block;

                @media screen and (max-width: 1279px) {
                    margin-top: 22.5vh;
                }

                ion-list {

                    @media screen and (max-width: 1279px) {
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 15px;
                    }

                    >ion-item {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        overflow: hidden;
                        background: var(--ion-item-background);
                        --background: var(--ion-item-background);
                        border: 3px solid rgba(255, 255, 255, 0.25) !important;
                        --border-radius: 20px !important;
                        border-radius: 20px !important;
                    }
                }
            }

        }

        @media screen and (max-width: 1279px) {
            h1.headline {
                --color: #fff;
                color: #fff;
            }
        }
    }

}