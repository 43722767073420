ion-header,
ion-footer,
ion-card,
ion-item,
ion-list {
    --background: rgba(var(--ion-card-background-rgb), 0.85) !important;
    background: rgba(var(--ion-card-background-rgb), 0.85) !important;

    ion-searchbar,
    ion-toolbar {
        --background: transparent !important;
        background: transparent !important;
    }
}

ion-card,
ion-item,
ion-list {
    --border-radius: 10px !important;
    border-radius: 10px !important;
}

ion-content {

    ion-searchbar {
        --background: transparent !important;
        background: transparent !important;

        input {
            --background: rgba(var(--ion-card-background-rgb), 0.85) !important;
            background: rgba(var(--ion-card-background-rgb), 0.85) !important;
            --border-radius: 10px !important;
            border-radius: 10px !important;
        }
    }

    ion-toolbar {
        --border-radius: 10px !important;
        border-radius: 10px !important;

        &:not(.search-toolbar) {
            --background: rgba(var(--ion-card-background-rgb), 0.65) !important;
            background: rgba(var(--ion-card-background-rgb), 0.65) !important;
            margin-top: 10px !important;
        }

        &.search-toolbar {
            --background: transparent !important;
            background: transparent !important;
        }
    }

}

/*
ion-card {
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
}
*/