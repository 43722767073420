html {

    app-media-library {

        &::before {
            content: '';
            --background: transparent !important;
            background: url(./../../assets/img/login/pipeline-devices-dark-profile-home-de.png) no-repeat center center var(--pipeline-login-web-left-area-background-color, #e2d8ff) !important;
            display: block !important;
            background-size: cover !important;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100vh;
            filter: grayscale(20%) contrast(150%) brightness(50%) !important;
        }

        ion-content {
            --background: transparent !important;
            background: transparent !important;

            > .container {
                > ion-grid {
                    padding-top: 30px;

                    > ion-row {
                        > ion-col {
                            ion-item {
                                --background: transparent !important;
                                background: transparent !important;
                            }
                        }
                    }
                }
            }

            pipeline-projects-card {
                margin-top: 15px !important;
                display: block;
            }

        }
    }

}